<template>
  <div
    v-if="variant === 'full'"
    class="language-selector-full"
  >
    <Item
      dropdown
      :name="$t(selectedLanguage.title) || ''"
      :variant="variant"
      :open.sync="open"
    >
      <template #icon>
        <component :is="selectedLanguage.icon" />
      </template>
    </Item>
    <div
      v-if="open"
      class="selectable-languages"
    >
      <Item
        v-for="language in selectableLanguages"
        :key="language.id"
        :name="$t(language.title) || ''"
        :id="language.id"
        :currentLanguage="selectedLanguage.id"
        :variant="variant"
        @click="() => changeLanguage(language.slug)"
        class="items"
      >
        <template #icon>
          <component :is="language.icon" />
        </template>
      </Item>
    </div>
  </div>

  <div v-else>
    <div
      id="language_selector_short"
      class="language-selector-short"
      tabindex="0"
    >
      <Item
        :id="selectedLanguage.id"
        dropdown
        :name="$t(selectedLanguage.title) || ''"
        :variant="variant"
        :open.sync="open"
      >
        <template #icon>
          <component :is="selectedLanguage.icon" />
        </template>
      </Item>
    </div>
    <Popover
      :items="languagesList"
      target="language_selector_short"
      :hasLanguage="false"
      placement="rightbottom"
      triggers="focus"
    />
    <Tooltip
      :text="$t(title)"
      target="language_selector_short"
    />
  </div>
</template>

<script>
import Item from './LanguageSelectorItem.vue';
import Popover from '../Popover.vue';
import Tooltip from '../Tooltip.vue';

import BrazilFlag from '@core/assets/icons/brazil-flag.svg';
import USAFlag from '@core/assets/icons/usa-flag.svg';

const ICONS = { BrazilFlag, USAFlag };

export default {
  name: 'LanguageSelector',
  components: { Item, Popover, Tooltip },
  props: {
    variant: {
      type: String,
      required: true
    }
  },
  data() {
    var self = this;
    return {
      key: 1,
      open: false,
      current: self.$i18n.locale,
      title: 'Menu.Languages.Title',
      icons: ICONS,
      languagesList: [
        {
          id: 1,
          slug: 'pt-BR',
          title: 'Menu.Languages.Portuguese',
          icon: ICONS.BrazilFlag,
          click: () => self.changeLanguage('pt-BR')
        },
        {
          id: 2,
          slug: 'en-US',
          title: 'Menu.Languages.English',
          icon: ICONS.USAFlag,
          click: () => self.changeLanguage('en-US')
        }
      ]
    };
  },
  computed: {
    selectableLanguages() {
      return this.languagesList.slice().sort((a, b) => a.title.localeCompare(b.title));
    },
    selectedLanguage() {
      return this.languagesList.find((item) => item.slug === this.current);
    }
  },
  methods: {
    toggleSelector() {
      this.open = !this.open;
    },
    changeLanguage(slug) {
      this.$i18n.locale = slug;
      this.current = slug;
      localStorage.setItem('language', slug);
      this.open = false;
      this.$emit('closePopover', this.open);
    }
  }
};
</script>

<style lang="scss" scoped>
.selectable-languages,
.language-selector-full {
  display: flex;
  flex-direction: column;
}
.language-selector-full {
  position: relative;
}

.selectable-languages {
  position: absolute;
  padding: 8px 0;
  top: 52px;
  width: 232px;
  background-color: #fff;
  border-radius: 6px;
}

.language-selector-short {
  min-width: 48px;
}
</style>

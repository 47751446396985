<template>
  <b-nav-item-dropdown
    variant="link"
    :class="['nav-locale', type == 'userDropdown' && 'dropdown-item d-flex align-items-start']"
    right
  >
    <template
      #button-content
      class="dropdown-item d-flex align-items-center"
    >
      <div
        class="test"
        id="language-dropdown-item"
      >
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
        <IconArrowDown class="arrow-icon" />
      </div>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj)"
      :class="['item-locale']"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue';
  import IconArrowDown from '@/assets/images/pages/arrow-down-icon.svg';

  export default {
    components: {
      BNavItemDropdown,
      BDropdownItem,
      BImg,
      IconArrowDown
    },
    props: ['type'],
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
      currentLocale() {
        return this.locales.find((l) => l.locale === this.$i18n.locale);
      }
    },
    methods: {
      setLocale(localeObj) {
        this.$i18n.locale = localeObj.locale;
        localStorage.setItem('language', localeObj.locale);
      }
    },
    setup() {
      /* eslint-disable global-require */
      const locales = [
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English'
        },
        {
          locale: 'pt',
          img: require('@/assets/images/flags/br.png'),
          name: 'Portuguese'
        }
      ];
      /* eslint-disable global-require */

      return {
        locales
      };
    }
  };
</script>

<style></style>
